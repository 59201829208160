<template>
  <div>
    <app-page-edit page-title="Update Nilai Buku & Perolehan" page-toolbar-title="Update Berita Acara">
      <template #toolbar>
        <app-button @click="getData(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-log-data-dialog module_name="beritaacara" :id="berita_acara_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Accounting.BeritaAcara.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :loading="loading" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>
      <v-row>
        <v-col cols="12" lg="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3">
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.document_no_" placeholder="No." label="No." readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field type="date" dense disabled v-model="berita_acara_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.document_type" placeholder="Document Type" label="Document Type" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.source_document" placeholder="Source Document" label="Source Document" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.status" placeholder="Status" label="Status" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field dense disabled v-model="berita_acara_header.created_by_username" placeholder="Created By" label="Created By" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field type="datetime-local" dense disabled v-model="berita_acara_header.created_date" placeholder="Created Date" label="Created Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-textarea dense disabled rows="2" v-model="berita_acara_header.description" placeholder="Description" label="Description" outlined hide-details class="mb-2"></v-textarea>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-dialog ref="refModal1" v-model="modal1" :return-value.sync="berita_acara_header.target_date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field type="date" dense disabled v-model="berita_acara_header.target_date" placeholder="Target Date" label="Target Date" readonly outlined hide-details class="mb-2" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="berita_acara_header.target_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="
                      $refs.refModal1.save(berita_acara_header.target_date)
                      ">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-dialog ref="refModal2" v-model="modal2" :return-value.sync="berita_acara_header.accumulated_depreciation_date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field type="date" dense v-model="berita_acara_header.accumulated_depreciation_date" placeholder="Accumulated Depreciation Date" label="Target Date" outlined hide-details class="mb-2" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="berita_acara_header.accumulated_depreciation_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="
                      $refs.refModal2.save(berita_acara_header.accumulated_depreciation_date)
                      ">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-dialog ref="refModal3" v-model="modal3" :return-value.sync="berita_acara_header.book_value_date" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field type="date" dense v-model="berita_acara_header.book_value_date" placeholder="Target Date" label="Book Value Date" outlined hide-details class="mb-2" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="berita_acara_header.book_value_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal3 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="
                      $refs.refModal3.save(berita_acara_header.book_value_date)
                      ">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </card-expansion>
          <!-- Items -->
          <card-expansion title="Items" class="mb-3">
            <v-row>
              <v-col>
                <v-toolbar elevation="0" dense>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="search" outlined append-icon="mdi-magnify" dense hide-details style="max-width: 200px;"></v-text-field>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 px-0">
                <v-card class="py-0 px-0" outlined elevation="0">
                  <v-data-table class="" :headers="berita_acara_item_columns" :items="berita_acara_item_data" :search="search" :items-per-page="20" fixed-header>

                    <template v-slot:[`item.item_description`]="{ item }">
                      <EditItem :selectedItemData="item" @editItemCallback="updateItem" :accountingMode="true">
                        <a href="javascript:;">{{ item.item_description }}</a>
                      </EditItem>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <span class="d-block text-end">{{ numberFormat(item.quantity, 2) }}</span>
                    </template>
                    <template v-slot:[`item.unit_price`]="{ item }">
                      <span class="d-block text-end">{{ numberFormat(item.unit_price, 2) }}</span>
                    </template>

                    <template v-slot:[`item.acquisition_value`]="{ item }">
                      <v-text-field type="number" outlined dense v-model="item.acquisition_value" v-on="calcItem(item)" hide-details></v-text-field>
                    </template>

                    <template v-slot:[`item.accumulated_depreciation`]="{ item }">
                      <v-text-field type="number" outlined dense v-model="item.accumulated_depreciation" :max="item.acquisition_value" v-on="calcItem(item)" hide-details></v-text-field>
                    </template>

                    <template v-slot:[`item.book_value`]="{ item }">
                      <span class="d-block text-end">{{ numberFormat(item.book_value, 2) }}</span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>

        <v-col cols="12" lg="3">
          <document-info :value="berita_acara_header"></document-info>

          <app-comment :document_id="parseInt(berita_acara_header.id)" :source_document="berita_acara_header.source_document" :document_no_="berita_acara_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-edit>
  </div>
</template>

<script>
import EditItem from "./components/EditItem";
import DocumentInfo from './components/DocumentInfo.vue';

export default {
  components: {
    EditItem,
    DocumentInfo,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,
      modal1: false,
      modal2: false,
      modal3: false,
      berita_acara_header: {},
      berita_acara_item_columns: [
        { text: "Item No.", value: "item_no_", sortable: false },
        {
          text: "Item Description",
          value: "item_description",
          sortable: false,
        },
        { text: "UoM", value: "unit_of_measure", sortable: false, align: "center" },
        { text: "Quantity", value: "quantity", sortable: false, align: "right" },
        // { text: "Weight", value: "weight", sortable: false },
        { text: "Unit Price", value: "unit_price", sortable: false, align: "right" },
        // { text: "Location Code", value: "location_code", sortable: false },
        // { text: "Bin Code", value: "bin_code", sortable: false },
        // { text: "Description", value: "description", sortable: false },
        { text: "Nilai Perolehan", value: "acquisition_value", sortable: false, align: "right" },
        { text: "Accumulated Depreciation", value: "accumulated_depreciation", sortable: false, align: "right" },
        { text: "Nilai Buku", value: "book_value", sortable: false, align: "right" },
      ],
      berita_acara_item_data: [],
      berita_acara_user: {},

      berita_acara_attachment: [],

      saveControl: false,

      search: "",
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    berita_acara_header(n, o) {
      this.saveControl = true
    }
  },
  methods: {
    async getData(refresh = false) {
      const uri = "beritaacara/detail";
      if (refresh == true)
        this.AxiosStorageRemove("GET", uri);

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get(uri, config)
        .then((res) => {
          this.berita_acara_header = res.data.data.berita_acara_header;
          this.berita_acara_item_data = res.data.data.berita_acara_item;
          this.berita_acara_user = res.data.data.user;
          this.berita_acara_attachment = res.data.data.berita_acara_attachment;
          this.showLoadingOverlay(false);
          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append(
        "berita_acara_header",
        JSON.stringify(this.berita_acara_header)
      );
      formData.append(
        "berita_acara_item",
        JSON.stringify(this.berita_acara_item_data)
      );
      formData.append(
        "berita_acara_attachment",
        JSON.stringify(this.berita_acara_attachment)
      );

      await this.$axios
        .post("beritaacara/save-accounting", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    updateItem(item) {
      var exists = false;

      var ol = this.berita_acara_item_data;
      var nl = [];

      ol.forEach((_item) => {
        if (_item.item_no_ == item.item_no_) {
          exists = true;
          _item = item;
        }

        nl.push(_item);
      });

      if (!exists) {
        this.showAlert("warning", "This item is not exists!");
      }

      this.berita_acara_item_data = nl;
    },

    calcItem(item) {
      item.book_value = item.acquisition_value - item.accumulated_depreciation;
    }
  },
  mounted() {
    this.id = this.$route.query.id;

    this.getData();
  },
};
</script>
